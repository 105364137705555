/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { LockIcon } from "../svg/icons";

export interface INowrVideoImage {
	url?: string;
	isVideo?: boolean;
	mdSize?: number;
	xsSize?: number;
	lockMode?: boolean;
	terminated?: boolean;
	ticketsLeft?: number;
	borderRadius?: number;
	thumbnailUrl?: string;
}

export function NowrVideoImage(props: INowrVideoImage) {
	const mdSize = props.mdSize || 60;
	const xsSize = props.xsSize || 40;

	const internalTicketsLeft = useMemo(() => {
		if (props.ticketsLeft === undefined || props.ticketsLeft === 10 || props.ticketsLeft === 0) return undefined;

		return props.ticketsLeft;
	}, [props.ticketsLeft]);

	return (
		<Box
			css={css`
				position: relative;
				width: ${mdSize}px;
				height: ${mdSize}px;
				margin-right: 15px;
				border-radius: ${props?.borderRadius ?? 16}px;

				@media screen and (max-width: 599px) {
					width: ${xsSize}px;
					height: ${xsSize}px;
				}
			`}
		>
			{props.lockMode && (
				<div
					css={{
						position: "absolute",
						top: "24%",
						left: "17%",
						width: "66%",
						height: "54%",
						borderRadius: 16,
						backgroundColor: "rgba(12, 25, 23, 0.38)",
						backdropFilter: "blur(27.18px)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<LockIcon />
				</div>
			)}
			{(props.terminated || internalTicketsLeft != undefined) && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						textAlign: "center",
						borderTopLeftRadius: 16,
						borderTopRightRadius: 16,
						background: "radial-gradient(100% 400% at 100% 100%, #FF44A8 0%, #F24C55 100%) ",
					}}
				>
					<Typography
						color="white"
						variant="overline"
						fontSize={mdSize === 60 ? 8 : 10}
						children={
							props.terminated ? "Terminato" : `${internalTicketsLeft} rimast${internalTicketsLeft === 1 ? "o" : "i"}`
						}
					/>
				</Box>
			)}
			{props.isVideo ? (
				<video
					muted
					autoPlay
					loop
					poster={props.thumbnailUrl}
					src={props.url}
					css={css`
						width: ${mdSize}px;
						height: ${mdSize}px;
						object-fit: cover;

						border-radius: ${props?.borderRadius ?? 16}px;

						@media screen and (max-width: 599px) {
							width: ${xsSize}px;
							height: ${xsSize}px;
						}
					`}
				/>
			) : (
				<img
					alt=""
					crossOrigin=""
					src={props?.url || ""}
					css={css`
						width: ${mdSize}px;
						height: ${mdSize}px;
						object-fit: cover;

						border-radius: ${props?.borderRadius ?? 16}px;

						@media screen and (max-width: 599px) {
							width: ${xsSize}px;
							height: ${xsSize}px;
						}
					`}
				/>
			)}
		</Box>
	);
}
